export const CONFIG_CONSTANTS = {

  APP_NAME: "RentVip",

  CURRENCY: {
    AVAILABLE: {
      EUR: {
        TYPE: 'EUR',
        SYMBOL: '€',
        countries: [
          "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI",
          "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU",
          "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE",
        ]
      },
      QAR: { TYPE: 'QAR', SYMBOL: '﷼', countries: ["QA"] },
      SAR: { TYPE: 'SAR', SYMBOL: '﷼', countries: ["SA"] },
      AED: { TYPE: 'AED', SYMBOL: 'د.إ', countries: ["AE"] },
      USD: { TYPE: 'USD', SYMBOL: '$', countries: [] },
    },
    DEFAULT: { TYPE: 'USD', SYMBOL: '$' }
  },

  LANGUAGES: {
    AVAILABLE: {
      EN: { TYPE: 'EN', VALUE: 'en', NATIVE_NAME: 'English', countries: ["AE", "US", "GB", "CA", "AU", "NZ"] },
      AR: { TYPE: 'AR', VALUE: 'ar', NATIVE_NAME: 'العربية', countries: ["SA", "QA", "EG", "MA"] },
      HI: { TYPE: 'HI', VALUE: 'hi', NATIVE_NAME: 'हिन्दी', countries: ["IN"] },
      ES: { TYPE: 'ES', VALUE: 'es', NATIVE_NAME: 'Español', countries: ["ES", "MX", "AR", "CO", "CL"] },
      RU: {
        TYPE: 'RU',
        VALUE: 'ru',
        NATIVE_NAME: 'Русский',
        countries: ["AM", "AZ", "BY", "KZ", "KG", "MD", "RU", "TJ", "TM", "UZ"]
      },
      UA: { TYPE: 'UA', VALUE: 'ua', NATIVE_NAME: 'Українська', countries: ["UA"] }
    },
    DEFAULT: { TYPE: 'EN',  VALUE: 'en', NATIVE_NAME: 'English' }
  },

  //DATE
  DATE: {
    FORMAT: {
      URL: 'DD-MM-YYYY',
    },
  },

  //LOGIC
  LOGIC_SETTINGS: {
    SEARCH: {
      GUESTS: {
        ADULTS: { MIN: 0, MAX: 10, DEFAULT: 2 },
        CHILDREN: { MIN: 0, MAX: 5, DEFAULT: 0 },
        INFANTS: { MIN: 0, MAX: 3, DEFAULT: 0 },
      },
      ROOM: { MIN: 0, MAX: 10, DEFAULT: 1 },
    },
    BOOKING: {
      CRYPTO_COINS: [ "USDTTRC20", "BTC", "ETH", "LTC", "DOGECOIN", "TRX", "SOL", "BNBBSC", "TON", "XRP" ],
      GUESTS: {
        ADULTS: { MIN: 1, MAX: 10, DEFAULT: 1 },
        CHILDREN: { MIN: 0, MAX: 5, DEFAULT: 0 },
        INFANTS: { MIN: 0, MAX: 2, DEFAULT: 0 },
      },
    }
  },

  //UI
  UI_SETTINGS: {
    FOOTER: {
      ADDRESS: 'Jumeriah beach residence - AI Fattan Marine Tower Office P05',
      MANAGER_BY: '4RENT PORTAL SERVICES CO.L.L.C',
      CONTACT_US: {
        TEL: '+971-56-227-5555',
        EMAIL: 'agent@rentvip.com',
      },
    },
    HOME: {
      OFFER_BLOCK: {
        SWIPER: {
          SLIDES_PER_VIEW: {
            MOBILE: 1.2,
          },
        },
        LIST_QUANTITY: {
          PROPERTY: {
            DESKTOP: 6,
            MOBILE: 6,
          },
          CAR: {
            DESKTOP: 6,
            MOBILE: 6,
          },
          TOUR: {
            DESKTOP: 3,
            MOBILE: 3,
          },
        }
      }
    },
    SEARCH: {
      LIST_QUANTITY: {
        PROPERTY: {
          DESKTOP: 20,
          MOBILE: 15,
        },
        CAR: {
          DESKTOP: 20,
          MOBILE: 15,
        },
        TOUR: {
          DESKTOP: 20,
          MOBILE: 15,
        },
      }
    },
    DETAILS: {
      GALLERY: {
        MAIN: {},
        FULL_SCREEN: {
          ZOOM: {
            DESKTOP: { MAX_RATIO: 3 },
            MOBILE: { MAX_RATIO: 3 },
          },
          SLIDES_PER_VIEW: {
            DESKTOP: 10,
            MOBILE: 5,
          },
          SPACE_BETWEEN: {
            DESKTOP: 10,
            MOBILE: 0,
          },
        }
      }
    }
  },
};