import {lazy} from "react";
import {CONSTANTS} from "../../constants/constants";

const Home = lazy(() => import("../../pages/Home/Home"));
const NotFoundPage = lazy(() => import("../../pages/NotFoundPage/NotFoundPage"));
const Auth = lazy(() => import("../../pages/Auth/Auth"));

const PropertyList = lazy(() => import("../../pages/searchLists/PropertyList/PropertyList"));
const CarList = lazy(() => import("../../pages/searchLists/CarList/CarList"));
const TourList = lazy(() => import("../../pages/searchLists/TourList/TourList"));

const PropertyDetails = lazy(() => import("../../pages/details/PropertyDetails/PropertyDetails"));
const CarDetails = lazy(() => import("../../pages/details/CarDetails/CarDetails"));
const TourDetails = lazy(() => import("../../pages/details/TourDetails/TourDetails"));

const BookingProperty = lazy(() => import("../../pages/booking/BookingProperty/BookingProperty"));
const BookingCar = lazy(() => import("../../pages/booking/BookingCar/BookingCar"));

export const publicRoutes = {
  [CONSTANTS.ROUTES.NOT_FOUND.path]: NotFoundPage,
  [CONSTANTS.ROUTES.AUTH.path]: Auth,
  [CONSTANTS.ROUTES.HOME.path]: Home,
  [CONSTANTS.ROUTES.PROPERTY.path]: Home,
  [CONSTANTS.ROUTES.CAR.path]: Home,
  [CONSTANTS.ROUTES.TOUR.path]: Home,
  [CONSTANTS.ROUTES.PROPERTY_SEARCH.path]: PropertyList,
  [CONSTANTS.ROUTES.CAR_SEARCH.path]: CarList,
  [CONSTANTS.ROUTES.TOUR_SEARCH.path]: TourList,
  [CONSTANTS.ROUTES.PROPERTY_DETAILS.path]: PropertyDetails,
  [CONSTANTS.ROUTES.CAR_DETAILS.path]: CarDetails,
  [CONSTANTS.ROUTES.TOUR_DETAILS.path]: TourDetails,
  [CONSTANTS.ROUTES.PROPERTY_BOOKING.path]: BookingProperty,
  [CONSTANTS.ROUTES.CAR_BOOKING.path]: BookingCar,
};