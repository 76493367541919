import { createSlice } from '@reduxjs/toolkit';
import moment from "moment";
import {CONSTANTS} from "../../constants/constants";

const GUESTS = CONSTANTS?.CONFIG?.LOGIC_SETTINGS?.SEARCH?.GUESTS;

const initialState = {
  property: {
    areaId: null,
    checkIn: moment().startOf('day').format(),
    checkOut: moment().add(1, 'days').startOf('day').format(),
    adults: GUESTS?.ADULTS?.DEFAULT || null,
    children: GUESTS?.CHILDREN?.DEFAULT || null,
    infants: GUESTS?.INFANTS?.DEFAULT || null,
    bedRooms: GUESTS?.DEFAULT || null,
  },
  car: {
    categoryId: [],
    checkIn: moment().startOf('day').format(),
    checkOut: moment().add(1, 'days').startOf('day').format(),
    cityId: null,
  },
  tour: {
    categoryId: [],
    cityId: null,
    adults: null,
    children: null,
    infants: null,
  },
};

const homeOptionsSlice = createSlice({
  name: 'homeOptionsSlice',
  initialState,
  reducers: {
    setPropertyOptions: (state, action) => {
      const { key, value } = action.payload;
      state.property[key] = value;
    },
    setCarOptions: (state, action) => {
      const { key, value } = action.payload;
      state.car[key] = value;
    },
    setTourOptions: (state, action) => {
      const { key, value } = action.payload;
      state.tour[key] = value;
    },
  }
});

export const {
  setPropertyOptions,
  setCarOptions,
  setTourOptions,
} = homeOptionsSlice.actions;

export default homeOptionsSlice.reducer;