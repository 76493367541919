import {useEffect} from 'react';
import {smile} from "./utils/smile";
import {useDispatch, useSelector} from 'react-redux';
import {setIsMobile} from "./redux/slices/layoutSlice";
import {resetUser} from "./redux/slices/userSlice";
import {CONSTANTS} from "./constants/constants";
import {getUserThunk, initializeCurrencyThunk, initializeLanguageThunk} from "./redux/thunks/userThunk";
import {useTranslation} from "react-i18next";

export const useAppInitialization = () => {

  useEffect(() => smile(), []); //smile

  const dispatch = useDispatch();

  const { i18n } = useTranslation();

  //auth
  const {isAuth, currency, language} = useSelector(state => state.user);

  useEffect(() => {
    if (isAuth) {
      dispatch(getUserThunk());
    } else {
      dispatch(resetUser());
    }
  }, [isAuth, dispatch]);

  //CURRENCY
  useEffect(() => {
    const isCurrencyExist = !!currency ? Object.values(CONSTANTS?.CONFIG?.CURRENCY?.AVAILABLE)
      .some((cur) => cur.TYPE === currency.TYPE) : false;

    if (!isCurrencyExist) {
      dispatch(initializeCurrencyThunk());
    }
  }, [dispatch, currency]);

  //LANGUAGES
  useEffect(() => {
    const isLanguageExist = !!language ? Object.values(CONSTANTS?.CONFIG?.LANGUAGES?.AVAILABLE)
      .some((cur) => cur.TYPE === language.TYPE) : false;

    if (isLanguageExist && i18n.resolvedLanguage !== language.VALUE) {
      i18n.changeLanguage(language.VALUE).then(() => null);
      document.documentElement.lang = i18n.language;
    }

    if (!isLanguageExist)  {
      dispatch(initializeLanguageThunk());
    }
  }, [dispatch, language, i18n]);

  //windowWidth
  useEffect(() => {
    const handleResize = () => {
      dispatch(setIsMobile(window.innerWidth <= 768));
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);
};