import styles from "./Layout.module.css";
import React, {Suspense, useMemo} from "react";
import {useSelector} from "react-redux";
import {LoadingScreen} from "../components/LoadingScreen/LoadingScreen";
import {Outlet, useLocation} from "react-router-dom";
import {MobileNav} from "../components/MobileNav/MobileNav";
import {CONSTANTS} from "../constants/constants";

export const Layout = () => {

  const isMobile = useSelector(state => state.layout.isMobile);
  const location = useLocation();
  const { pathname } = location;

  const hiddenRoutes = useMemo(() => ([
    CONSTANTS.ROUTES.HOME.path,
    CONSTANTS.ROUTES.PROPERTY.path,
    CONSTANTS.ROUTES.CAR.path,
    CONSTANTS.ROUTES.TOUR.path,
    CONSTANTS.ROUTES.PROFILE.path,
    CONSTANTS.ROUTES.AUTH.path,
  ]), []);

  return (
    <div className={styles.wrapper}>
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
      {hiddenRoutes?.includes(pathname) && isMobile && <MobileNav />}
    </div>
  )
};